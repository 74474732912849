const supportedLangs = [
  {
    ISO6391: 'en',
    ISO6392: 'eng'
  },
  {
    ISO6391: 'fr',
    ISO6392: 'fra'
  }
]

export default supportedLangs